import { fjuelApi } from '@fjuel/configs/api';
import { SessionPrices, SessionPricesSchema } from '@fjuel/schemas';
import { minutesToMilliseconds } from 'date-fns';
import { createQuery } from 'react-query-kit';
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

type GetSessionPriceQueryParams = {
  sessionId: string | undefined;
  from?: Date;
  to?: Date;
};

export const useSessionPrice = createQuery<SessionPrices, GetSessionPriceQueryParams>({
  primaryKey: 'session-price',
  queryFn: ({ queryKey: [_, variables] }) => getSessionPrice(variables),
  staleTime: minutesToMilliseconds(30),
  gcTime: minutesToMilliseconds(60),
  use: [disableIfVariablesUndefined('sessionId')],
});

export const getSessionPrice = async ({ sessionId, from, to }: GetSessionPriceQueryParams): Promise<SessionPrices> => {
  if (!sessionId) return [];
  const searchParams = new URLSearchParams();
  if (from) {
    searchParams.append('from', from.toJSON());
  }
  if (to) {
    searchParams.append('to', to.toJSON());
  }

  try {
    const raw = await fjuelApi.get(`session/${sessionId}/prices`, {
      searchParams,
    });
    const json = await raw.json();
    return SessionPricesSchema.parse(json);
  } catch (error) {
    console.error('Error fetching or parsing session prices:', error);
    throw new Error('Failed to fetch or parse session prices');
  }
};
