import { fjuelApi } from '@fjuel/configs/api';
import { IPC, IPCSchema } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';
import { disableIfVariablesUndefined } from './middlewares/disableIfVariablesUndefined';

export const useSiteIpcs = createQuery<IPC[], { siteId: string | undefined }>({
  primaryKey: 'site-ipcs',
  queryFn: async ({ queryKey: [, { siteId }] }) => {
    const raw = await fjuelApi.get(`site/${siteId}/ipcs`);
    const json = await raw.json();
    return z.array(IPCSchema).parse(json);
  },
  use: [disableIfVariablesUndefined(['siteId'])],
});
